<template>
  <div>
    <v-tabs
      v-model="activeTab"
      vertical
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
        class="justify-start"
        :to="tab.route" exact
      >
        <v-icon left>{{ tab.icon }}</v-icon>
        {{ tab.text }}
      </v-tab>
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.id"
        :id="tab.route"
      >
        <router-view v-if="activeTab === tab.route" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: '/admin',
      tabs: [
        {
          id: 1,
          text: "Session",
          icon: 'mdi-account-multiple',
          route: '/admin/session'
        },
        {
          id: 2,
          text: "Benachrichtigung",
          icon: 'mdi-message',
          route: '/admin/news'
        }
      ]
    };
  }
}
</script>
